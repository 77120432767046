import * as React from "react"
import {Link} from "gatsby"
import styled, {keyframes} from "styled-components";

// styles
const pageStyles = {
	color: "#232129",
	padding: "96px",
	fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
}

const paragraphStyles = {
	marginBottom: 48,
}
const codeStyles = {
	color: "#8A6534",
	padding: 4,
	backgroundColor: "#FFF4DB",
	fontSize: "1.25rem",
	borderRadius: 4,
}

// markup
const NotFoundPage = () => {
	const browser = typeof window !== "undefined" && window;
	return (
		browser && (
			<div>
				<h1>404 Error content...</h1>
			</div>
		)
	);
};

export default NotFoundPage
